
import * as React from "react";
import * as ReactDOM from "react-dom";
import List from "../avernus/list/source/List";
import Maps, { IMarker } from "../avernus/googlemaps/source/Maps";
import Finder from "./Finder";
import Ajax from "../avernus/ajax/Ajax";
import Modal from "../avernus/modal_react/source/Modal";
interface IOffer {
    id:number
    name:string
    description:string
    price:number
    salePrice: number
    amount:number
    expire:string
    categoryId:number
    categoryName:string

}


interface IItem extends IMarker{
    restaurantId:number
    address:string
    description:string
    closingTime:string
    rating:number,
    offers: Array<IOffer>
    offerName?:string
    offerCategory?:string
    offerExpire?:string
}

interface IRestaurantsAppProps {
    setHeightCb:Function
    lang:string
    back_btn_text:string
    close_btn_text:string
    order_btn_text:string
    restaurant_tab_text:string
    offer_tab_text:string
    table_header_food_name_text:string
    table_header_food_category_name_text:string
    table_header_food_price_text:string
    table_header_food_sale_price_text:string
    end_text:string
    download_text:string
    placeholder_restaurant_finder_placeholder:string
    placeholder_offer_finder_placeholder:string
}

interface IRestaurantsAppState {
    items:Array<IItem>
    titles:Array<any>
    filter:string
    selected:boolean
    openRestaurantModal:boolean
    currentRestaurant:IItem
    openModal:boolean
    showOrderOnlineNotice:boolean
    message:string
    currentTab:string
}

export class RestaurantsApp extends React.Component<IRestaurantsAppProps, IRestaurantsAppState>{

    private lastFilter:Array<IItem>;

  	constructor(props:IRestaurantsAppProps){
        super(props);
        this.lastFilter = [];
        var filter      = '';
        var paramName   = '?open=';
        var paramIndex  = window.location.href.indexOf(paramName);
        if ( paramIndex > -1 ) {
            filter = decodeURI(window.location.href.toString().substr(paramIndex+paramName.length).replace('#',''));
        }
        this.state = {
            message: 'Betöltés...',
            openModal: false,
            filter: filter,
            titles: [
                {
                    field: 'title',
                    label: 'title'
                },
                {
                    field: 'address',
                    label: 'address'
                }
            ],
            items: [],
            selected: false,
            openRestaurantModal: false,
            showOrderOnlineNotice: false,
            currentRestaurant: null,
            currentTab: 'restaurants'
        };

        this.init();
    }

    private init():void{
        var url:string='/api';
        if ( this.props.lang === 'EN' ) {
            url = '/api-en';
        }
        if ( this.props.lang === 'DE' ) {
            url = '/api-de';
        }
        new Ajax(url+'/init', 'POST', {}, (ret) =>{
            this.setState({
                 items: ret.items,
                 message: 'Nincs találat!'
            });

        });
    }

    private changeFilter(str): void {
        this.setState({
             filter: str,
             selected: false
        });
    }

    private changedOpen(): void {

    }

    protected filter(filterBy:Array<any>, explodeToOfferRows:boolean=false):Array<IItem>{
        var items           = this.state.items;
        if ( explodeToOfferRows === true ) {
            items = [];
            for (var key in this.state.items) {
                let item = this.state.items[key];
                if ( item.restaurantId === 13 ) {
                    continue;
                }
                for (var okey in item.offers) {
                    var oitem              = item.offers[okey];
                    let newItem            = JSON.parse(JSON.stringify(item));
                    newItem.offerName      = oitem.name;
                    newItem.offerCategory  = oitem.categoryName;
                    newItem.offerExpire    = oitem.expire;
                    items.push(newItem);
                }
            }
        }

		var ret:Array<any>  = [];
        var filter:string   = this.state.filter.toString().trim().toLowerCase();
		for (var ikey in items) {
		    var row = items[ikey];
            if ( row.restaurantId === 13 ) {
                continue;
            }
			if ( filter === '' ) {
				ret.push(row);
				continue;
			}else{
				for (var key in filterBy) {
					var item = filterBy[key];
					if ( row[item.field].toLowerCase().indexOf(filter) > -1 ) {
                        if ( ret.indexOf(row) == -1 ) {
						    ret.push(row);
                        }
						break;
					}
				}
			}
		}
		return ret;
	}

    private checkItemsChanged(items:Array<IItem>):boolean{
        if ( this.lastFilter.length !== items.length ) {
            // console.log(items, this.lastFilter);
            this.lastFilter = items;
            return true;
        }
        return false;
    }

    private openRetaurantClickHandler(item:IItem): void {
        document.getElementById('home_map').classList.add('backToModal');

        this.setState({
            currentRestaurant: item,
            openRestaurantModal: true,
            showOrderOnlineNotice: false,
            // filter: item.title,
            selected: true
        });

    }

    private filterRetaurantClickHandler(item:IItem): void {
    //    this.setState({
    //         filter: item.title,
    //         selected: true
    //    });
        this.openRetaurantClickHandler(item);

    }

    private closeRestaurantModalClickHandler(evt?:Event): void {
        if ( evt !== undefined ) {
            evt.preventDefault();
        }
        document.getElementById('home_map').classList.remove('backToModal');
        this.setState({
             openRestaurantModal: !this.state.openRestaurantModal
        });
    }

    private backClickHandler(evt): void {
        evt.preventDefault();
        this.setState({
            filter: '',
            selected: false
        });
    }

    private clickOrderHandler(evt): void {
        evt.preventDefault();
        this.setState({
             showOrderOnlineNotice: !this.state.showOrderOnlineNotice
        });

    }

    private  changeTabClickHandler(evt, target:string): void {
        evt.preventDefault();
        this.setState({
             currentTab: target,
             filter: '',
             selected: false
        }, () =>{
             this.props.setHeightCb();
        });

    }



    render() {
        var items = [];
        if ( this.state.currentTab === 'restaurants' ) {
            items = this.filter(this.state.titles);
        }else{
             items = this.filter([
                {
                    field: 'offerName',
                    label: 'offerName'
                },
                // {
                //     field: 'offerCategory',
                //     label: 'offerCategory'
                // }
            ], true);
        }
        setTimeout(()=>{
            this.props.setHeightCb();
        }, 800);
        return (
            <div>
                { this.state.openRestaurantModal === true?<Modal class="restaurant_modal" title="" closed={ () => this.closeRestaurantModalClickHandler() }>
                    <div id="download_pdf_modal">
                        { this.state.currentRestaurant.restaurantId > 0?<div className="col2">
                            <div className="box">
                                <img src="/img/v2/restaurant.svg" alt="ikon" />
                            </div>
                            <div className="box info">
                                <strong>
                                    { this.state.currentRestaurant.title }
                                        <div className="rateing" data-rateing={ this.state.currentRestaurant.rating }>
                                            <span className={ this.state.currentRestaurant.rating <= 1? 'fa fa-star checked' : 'fa fa-star' }></span>
                                            <span className={ this.state.currentRestaurant.rating === 2? 'fa fa-star checked' : 'fa fa-star' }></span>
                                            <span className={ this.state.currentRestaurant.rating === 3? 'fa fa-star checked' : 'fa fa-star' }></span>
                                            <span className={ this.state.currentRestaurant.rating === 4? 'fa fa-star checked' : 'fa fa-star' }></span>
                                            <span className={ this.state.currentRestaurant.rating === 5? 'fa fa-star checked' : 'fa fa-star' }></span>

                                        </div>
                                </strong>
                                { this.state.currentRestaurant.address }
                            </div>
                        </div>:'' }


                        <div className="modal_inner_content">
                            <p>
                                { this.state.currentRestaurant.description }
                            </p>
                            { this.state.currentRestaurant.offers.length > 0?<div>
                                <h3>{ this.props.offer_tab_text }:</h3>
                                <div className="offers">
                                    <table className="table">
                                        <tr>
                                            <th>{ this.props.table_header_food_name_text }</th>
                                            <th>{ this.props.table_header_food_category_name_text }</th>
                                            <th>{ this.props.table_header_food_price_text }</th>
                                            <th>{ this.props.table_header_food_sale_price_text }</th>
                                            <th></th>
                                        </tr>
                                    { this.state.currentRestaurant.offers.map((item, key) => {
                                        return <tr key={key} >
                                            <td>{ item.name }</td>
                                            <td>{ item.categoryName}</td>
                                            <td><s>{ item.price } Ft</s></td>
                                            <td><strong>{ item.salePrice } Ft</strong></td>
                                            <td><i>{ item.expire }{ this.props.end_text }</i></td>
                                        </tr>
                                    })}
                                   </table>
                                </div>
                                { this.state.showOrderOnlineNotice === true? <div className="order_online_notice">
                                    <h3>{ this.props.download_text }</h3>
                                    <div>
                                        <a className="gp_icon" href="https://play.google.com/store/apps/details?id=hu.redinner.user" target="_blank"></a>
                                        <a className="as_icon" href="https://itunes.apple.com/hu/app/redinner/id1195817814?mt=8" target="_blank"></a>
                                    </div>

                                </div>:<div className="order_online_notice">
                                    <a className="btn order" onClick={ (e) => { this.clickOrderHandler(e); } }>
                                        <span className="order_btn_icon"></span> <span className="text">{ this.props.order_btn_text }</span>
                                    </a>
                                </div>
                                }
                            </div>:'' }




                            <div className="center">
                                <a className="btn close_button close_btn" onClick={ () => { this.closeRestaurantModalClickHandler(); } }>
                                    <span className="text">{ this.props.close_btn_text }</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </Modal>:'' }

                <div className="col2">
                    <div className="box">
                        <div className="tabs">
                            <ul className="header">
                                <li className={ this.state.currentTab === 'restaurants'?'active':'' } >
                                    <a onClick={ (e) => { this.changeTabClickHandler(e, 'restaurants'); } }>
                                        { this.props.restaurant_tab_text }
                                    </a>
                                </li>
                                <li className={ this.state.currentTab === 'offers'?'active':'' } >
                                    <a onClick={ (e) => { this.changeTabClickHandler(e, 'offers'); } }>
                                        { this.props.offer_tab_text }
                                    </a>
                                </li>
                            </ul>
                            <div className="body">
                                <div className={ this.state.currentTab === 'restaurants'?'active':'' }>
                                    <Finder placeholder={ this.props.placeholder_restaurant_finder_placeholder } filter={this.state.filter} open={true} changed={ (str) => this.changeFilter(str) } changedOpen={ () => this.changedOpen()}  />
                                    { items.length === 0?<div className="not_found">
                                        { this.state.message }
                                    </div>:<div className="list results_div">
                                        <List
                                            titles = {
                                                this.state.titles
                                            }
                                            items = {
                                                items
                                            }
                                            rowTemplate = {
                                                (item) => {
                                                    var className = 'row';
                                                    if ( item.expired === true ) {
                                                        className += ' expired';
                                                    }
                                                    if ( this.state.selected === true && item.restaurantId == this.state.currentRestaurant.restaurantId ) {
                                                        className += ' active';
                                                    }
                                                    if ( item.restaurantId == 0 ) {
                                                        return <></>
                                                    }
                                                    return <a onClick={ e => {
                                                        e.preventDefault();
                                                        this.openRetaurantClickHandler(item);
                                                    }} target="_blank" className={ className } key={'_restaurants_'+item.lat+'_'+item.lng+'_'+ Math.random()}>
                                                        <div className="col2">
                                                            <div className="box">
                                                                <img src="/img/v2/restaurant.svg" alt=""/>
                                                            </div>
                                                            <div className="box">
                                                                <span className="title">
                                                                    { item.title }
                                                                </span>
                                                                <span className="city">
                                                                    { item.address }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </a>;
                                                }
                                            }
                                        />
                                    </div>
                                    }
                                </div>
                                <div className={ this.state.currentTab === 'offers'?'active':'' }>
                                    <Finder placeholder={ this.props.placeholder_offer_finder_placeholder }filter={this.state.filter} open={true} changed={ (str) => this.changeFilter(str) } changedOpen={ () => this.changedOpen()}  />
                                    { items.length === 0?<div className="not_found">
                                        { this.state.message }
                                    </div>:<div className="list results_div">
                                        <List
                                            titles = {
                                                [
                                                    {
                                                        field: 'offerName',
                                                        label: 'offerName'
                                                    },
                                                    {
                                                        field: 'offerCategory',
                                                        label: 'offerCategory'
                                                    }
                                                ]
                                            }
                                            items = {
                                                items
                                            }
                                            rowTemplate = {
                                                (item) => {
                                                    var className = 'row';
                                                    if ( item.expired === true ) {
                                                        className += ' expired';
                                                    }
                                                    if ( item.restaurantId == 0 ) {
                                                        return <></>
                                                    }
                                                    // if ( this.state.selected === true && item.restaurantId == this.state.currentRestaurant.restaurantId ) {
                                                    //     className += ' active';
                                                    // }
                                                    return <a onClick={ e => {
                                                        e.preventDefault();
                                                        this.openRetaurantClickHandler(item);
                                                    }} target="_blank" className={ className } key={'_offfers_'+item.lat+'_'+item.lng+'_'+ Math.random()}>
                                                        <div className="col2">
                                                            <div className="box">
                                                                <img src="/img/v2/restaurant.svg" alt=""/>
                                                            </div>
                                                            <div className="box">
                                                                <span className="title">
                                                                    { item.offerName }
                                                                </span>
                                                                <span className="city">
                                                                    { item.offerCategory }
                                                                </span>
                                                                { item.offerExpire !== ''?<div>
                                                                    { item.offerExpire }{ this.props.end_text }
                                                                </div>:'' }
                                                            </div>
                                                        </div>
                                                    </a>;
                                                }
                                            }
                                        />
                                    </div>
                                    }
                                </div>


                            </div>

                        </div>
                        { this.state.filter != ''?<div>
                            <a onClick={ (e) => { this.backClickHandler(e); } } className="back_btn">
                                { this.props.back_btn_text }
                            </a>

                        </div>:'' }

                    </div>
                    <div className="box">
                        { this.checkItemsChanged(items) === true?<Maps
                           key="google_map"
                           api="AIzaSyAT-Sa4iX_7_9YD-UgHZ639KB44OhKuLks"
                           mapId="home_map"
                           externalDivMode={true}
                           width="100%"
                           height="100%"
                           mapTypeControl = {false}
                           mapStyle="silver"
                           icons = {[
                               {
                                   name: 'Info',
                                   url: '/img/v2/picker_googlemap.png'
                               },
                               {
                                   name: 'expired',
                                   url: '/img/v2/picker_googlemap_gray.png'
                               }
                           ]}
                           markers = {items}
                           zoom = { 12 }
                           markerClickHandler = { (item:IItem) =>{
                               this.filterRetaurantClickHandler(item);
                           }}
                       />:<div id="home_map_external_not_this" style={{
                           width: "100%",
                           height: "100%"
                       }}>

                       </div> }
                    </div>
                </div>
  			</div>
        );
    }
}
export class InitRestaurantsApp {
    constructor(cb:Function) {
        var div: HTMLDivElement = document.getElementById('restaurants_app') as HTMLDivElement;
        if (div !== null) {
            var data:any = div.dataset;
            ReactDOM.render(<RestaurantsApp
                setHeightCb={cb}
                lang={data.lang}
                back_btn_text={data.back_btn_text}
                close_btn_text={data.close_btn_text}
                order_btn_text={data.order_btn_text}
                restaurant_tab_text={data.restaurant_tab_text}
                offer_tab_text={data.offer_tab_text}
                table_header_food_name_text={data.table_header_food_name_text}
                table_header_food_category_name_text={data.table_header_food_category_name_text}
                table_header_food_price_text={data.table_header_food_price_text}
                table_header_food_sale_price_text={data.table_header_food_sale_price_text}
                end_text={data.end_text}
                download_text={data.download_text}
                placeholder_restaurant_finder_placeholder={data.placeholder_restaurant_finder_placeholder}
                placeholder_offer_finder_placeholder={data.placeholder_offer_finder_placeholder}
            />, div);
        }
    }
}
