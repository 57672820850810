/*
export interface MyWindows extends Window{
	downloadPdfModal: Modal;
}

(window as MyWindows).downloadPdfModal = new Modal($('#download_pdf_modal'), 'Letöltés', '', false);
*/
// import $ = require('jquery');
export default class tsModal{
	private content:any;
	private modal:any;
	private title:string;
	private footer:string;
    private modalClass:string;
	private mouseEnter:boolean;
	private closeCb:Function;
	constructor(o:JQuery, title:string, footer:string, open:boolean, closeCb?:Function){
		this.content = o;
		if ( this.content.parent().hasClass('modal_dialog') ) {
			this.modal = this.content.parents('.ts_modal:first');
			if ( open === true ) {
				this.modalOpenHandler(undefined);
			}
			return;
		}
        if (this.content.data('class') !== undefined ) {
            this.modalClass = this.content.data('class');
        }
		this.title = title;
		this.footer = footer;
		if ( closeCb === undefined ) {
			closeCb = null;
		}
		this.closeCb = closeCb;
		this.mouseEnter = false;
		this.make();
		this.events();

		if ( open === true ) {
			this.modalOpenHandler(undefined);
		}
	}

	private events():void{
	    this.modal.find('.close_btn, .close').off('click').on('click', (event) =>{
			this.modalCloseHandler(event);
		});
		var dialog:JQuery = this.modal.find('.modal_dialog');
		dialog.off('mouseenter').on('mouseenter', ()=>{
			this.mouseEnter = true;
		});
		dialog.off('mouseleave').on('mouseleave', ()=>{
			this.mouseEnter = false;
		});

	    this.modal.off('click').on('click', (event) =>{
			if ( this.mouseEnter  === false ) {
				this.modalCloseHandler(event);
			}
		});

	    $(window).scroll(() => {
			if ( this.modal !== null && this.modal.is(':visible') ) {
				var headerHeight = this.modal.find('.modal_dialog').height();
				if ( (window.outerHeight - headerHeight) > this.modal.find('.modal_dialog').height()) {
		            this.setPosition();
		        }
			}

	    });
	}

	public setCloseHandlerCb(Cb:Function){
		this.closeCb  = Cb;
	}

	private modalCloseHandler(event:any){
		if ( this.closeCb !== undefined && this.closeCb !== null ) {
			this.closeCb();
		}
		this.modal.fadeOut();
	}

	private setPosition():void{
		var currentScroll = $(window).scrollTop();
	    this.modal.css({
	        top: currentScroll
	    });
	}

	public open():void{
		this.modalOpenHandler(undefined);
	}

	public close():void{
		this.modalCloseHandler(undefined);
	}

	private modalOpenHandler(event:any):void{
		if (event !== undefined) {
			event.preventDefault();
		}

		this.modal.hide();
		this.setPosition();
		this.modal.fadeIn();
	}

	private make():void{
		this.content.addClass('modal_content');
		this.content.show();
		this.content.wrap('<div class="modal_dialog"></div>');
		this.content.parent().wrap('<div class="ts_modal ' + this.modalClass + '"></div>');
        if ( this.title !== '') {
            var close_btn = '<a href="javascript:void(0);" class="close_btn"><span class="ion-ios-close"></span></a>';
    		this.content.before('<div class="modal_header">' + this.title + close_btn + '</div>');
        }
		this.content.after('<div class="modal_footer">' + this.footer + '</div>');
		this.modal = this.content.parents('.ts_modal:first');
	}

	public moveToBody(){
		this.modal.appendTo('body');
	}
}
