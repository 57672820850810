import { InitRestaurants } from "./components/Restaurants";
import { InitRestaurantsApp } from "./components/RestaurantsApp";
import Modal from "./avernus/modal/source/Modal";
import { InitRestaurantContactModal } from "./components/RestaurantContactModal";
class Main {
    constructor() {
        new InitRestaurants();
        new InitRestaurantsApp(()=>{
            this.restaurantMapFullScreen();
        });
        new InitRestaurantContactModal();

        // slider
        window.addEventListener('load', ()=>{
            var slickBtns = document.querySelectorAll('.slick_open_modal');
            for (var key=0; key<slickBtns.length; ++key) {
                slickBtns[key].addEventListener('click', (e) =>{
                    e.preventDefault();
                    var $this = e.target as HTMLDivElement;
                    var $parent = $this.closest('.slick_open_modal') as HTMLDivElement;
                    new Modal($('#'+$parent.dataset.id),'', '', true);
                })
            }

            if ( window.location.href.indexOf('?success=true') > -1 ) {
                new Modal($('#contact_form_success'),'', '', true);
            }
            if ( window.location.href.indexOf('?registration=true') > -1 ) {
                new Modal($('#contact_form_success'),'', '', true);
            }
            this.restaurantMapFullScreen();
        });

        window.addEventListener('resize', (e) => {
            this.restaurantMapFullScreen();
        });
        if ( document.getElementById('modal_news') !== null ) {
            new Modal($('#modal_news'),'', '', true);
        }
        if (  document.querySelector('.restaurant_form_btn') !== null ) {
            document.querySelector('.restaurant_form_btn').addEventListener('click', (e) =>{
                e.preventDefault();
                document.getElementById('reastaurant_contact_modal').classList.toggle('hidden');

            });
            if ( window.location.href.indexOf('?jelentkezem') > -1 ) {
                document.getElementById('reastaurant_contact_modal').classList.toggle('hidden');
            }
        }

    }

    private restaurantMapFullScreen():void{
        var modifier:number = 0;
        if ( window.innerWidth < 860 ) {
            modifier = 450;
        }
        if ( document.getElementById('restaurants_app') !== null ) {
            var headerFooterHeight:number = document.querySelector('header').offsetHeight + document.querySelector('footer').offsetHeight;
            var centerHeight = window.innerHeight - headerFooterHeight + modifier;
            (document.querySelector('.restaurant_box') as HTMLDivElement).style.height = centerHeight.toString()+'px';
            var resultDiv                                                              = (document.querySelector('.restaurant_box').querySelector('.results_div') as HTMLDivElement);
            if (  resultDiv !== null ) {
                var tabHeaderHeight = 0;
                if (  document.querySelector('.tabs').querySelector('.header') !== null  ) {
                    tabHeaderHeight = (document.querySelector('.tabs').querySelector('.header') as HTMLDivElement).offsetHeight;
                }
                var resultDivs = document.querySelector('.restaurant_box').querySelectorAll('.results_div');
                for (var key in resultDivs) {
                    var item = resultDivs[key] as HTMLDivElement;
                    if ( item.style !== undefined ) {
                        item.style.maxHeight = (centerHeight-150 - tabHeaderHeight)+'px';
                    }

                }
            }


        }

    }
}
new Main();
