
import * as React from "react";

interface IFinderProps {
    filter:string
    open:boolean
    changed:Function
    changedOpen:Function
    placeholder:string
}

interface IFinderState {
}

export default class Finder extends React.Component<IFinderProps, IFinderState>{

  	constructor(props:IFinderProps){
        super(props);
    }

    private changeFilter(evt): void {
        evt.preventDefault();
        this.props.changed(evt.target.value)
    }

    private openChangeHandler(evt): void {
        evt.preventDefault();
        this.props.changedOpen()
    }



    render() {
        return (
            <div className="finder">
                { this.props.open === true?<div className="open">
                    <input type="input" value={ this.props.filter } onChange={ (e) => this.changeFilter(e) } placeholder={this.props.placeholder} />
                    <a className="icon" onClick={ (e) => this.openChangeHandler(e) }></a>
                </div>:<div className="close">
                    <a className="icon" onClick={ (e) => this.openChangeHandler(e) }></a>
                </div>}


			</div>
        );
    }
}
