
import * as React from "react";
import { Table, ITableProps, ITableState } from "../avernus/table/Table";


interface IListProps extends ITableProps {
    filter?:string
    rowTemplate?:React.Component //if use rowTempalte -> dont use clickHandler
    clickHandler?:Function
}

interface IListState extends ITableState {
}

export default class List extends Table{

  	constructor(props:IListProps){
        super(props);
        if ( this.props.filter !== undefined ) {
            this.state = {
                filter: this.props.filter
            }
        } 
    }

    render() {
        var items = this.filter();
        if ( this.props.rowTemplate !== undefined ) {
             return (
                <div>
                { items.map((row, rkey) => {
                    return this.props.rowTemplate(row);
                }) }
                </div>
            );
        }
        if ( this.props.clickHandler !== undefined ) {
             return (
                <div>
                { items.map((row, rkey) => {
                    return <a key={ rkey } className="list_row" onClick={ e => this.props.clickHandler(row) }
                    >
                        { this.props.titles.map((item, key) => {
                            return <div key={ key } className="cell">
                            {row[item.field]}
                            </div>
                        })}
                    </a>
                }) }
                </div>
            );
        }
        return (
            <div>
              { items.map((row, rkey) => {
                return <div key={ rkey } className="list_row" onClick={ e => this.props.clickHandler(row) }
                >
                    { this.props.titles.map((item, key) => {
                        return <div key={ key } className="cell">
                         {row[item.field]}
                        </div>
                    })}
                </div>
            }) }
			</div>
        );
    }
}
