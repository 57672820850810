
import * as React from "react";
import * as ReactDOM from "react-dom";
import Modal from "../avernus/modal_react/source/Modal";

interface IRestaurantContactModalProps {
    title:string
    f_3662:string
    name:string
    country:string
    city:string
    street:string
    zip:string
    f_984:string
    f_4234:string
    f_5325:string
    f_549:string
    contactLName:string
    contactFName:string
    contactEmail:string
    contactPhone:string
    f_3749:string
    f_7510:string
    vat_number:string
    reg_number:string
    contactAssignment:string
    szolgltatsi_szerzodes:string
    elkuldes:string
    bezaras:string
}

interface IRestaurantContactModalState {
}

export default class RestaurantContactModal extends React.Component<IRestaurantContactModalProps, IRestaurantContactModalState>{

  	constructor(props:IRestaurantContactModalProps){
        super(props);
    }

    private closeBtnClickHandler(evt): void {
        evt.preventDefault();
        document.getElementById('reastaurant_contact_modal').classList.toggle('hidden');
    }



    render() {
        return (
           <Modal class="restaurant_contact_modal open" title="" closed={ e => this.closeBtnClickHandler(e) }>
                <div>
                    <div className="modal_inner_content">

                        <h3>{ this.props.title }:</h3>

                          <form action="https://app.momocrm.hu/form/543d8485e1120fb3f81799e4f9408e316bd645adcae1c6bd63efa138da0d5f8c" method="post" className="form">
                            <input type="text" name="3662" required={true} placeholder={ this.props.f_3662 } />
                            <input type="text" name="name" required={true} placeholder={ this.props.name } />
                            <input type="text" name="country" required={true} defaultValue={ this.props.country } placeholder="Székhely - Ország" />
                            <input type="text" name="city" required={true} placeholder={ this.props.city } />
                            <input type="text" name="street" required={true} placeholder={ this.props.street } />
                            <input type="text" name="zip" required={true} placeholder={ this.props.zip } />
                            <input type="text" name="984" required={true} placeholder={ this.props.f_984 } />
                            <input type="text" name="4234" required={true} placeholder={ this.props.f_4234 } />
                            <input type="text" name="5325" required={true} placeholder={ this.props.f_5325 } />
                            <input type="text" name="549" required={true} placeholder={ this.props.f_549 } />
                            <input type="text" name="contactLName" required={true} placeholder={ this.props.contactLName } />
                            <input type="text" name="contactFName" required={true} placeholder={ this.props.contactFName } />
                            <input type="email" name="contactEmail" required={true} placeholder={ this.props.contactEmail } />
                            <input type="text" name="contactPhone" required={true} placeholder={ this.props.contactPhone } />
                            <textarea name="3749" id="" cols={30} rows={10} placeholder={ this.props.f_3749 }></textarea>

                            <input type="hidden" name="7510" value="redinner.com" placeholder={ this.props.f_7510 } />
                            <input type="hidden" name="vat_number" value="" placeholder={ this.props.vat_number } />
                            <input type="hidden" name="reg_number" value="" placeholder={ this.props.reg_number } />
                            <input type="hidden" name="contactAssignment"  value="" placeholder={ this.props.contactAssignment } />


                            <div className="center">
                                <a href="/uploads/content/68/documents/redinner_szolgaltatasi_szerzodes_online_2018.pdf" target="_blank" className="link" style={{
                                    marginRight: '30px'
                                }}>
                                    { this.props.szolgltatsi_szerzodes }
                                </a>
                                <button type="submit" className="btn send">{ this.props.elkuldes }!</button>
                            </div>
                        </form>
                        <div className="center">
                            <a className="btn close_button close_btn" onClick={ (e) => { this.closeBtnClickHandler(e); } }>
                                <span className="text">{ this.props.bezaras }</span>
                            </a>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
export class InitRestaurantContactModal {
    constructor() {
        var div: HTMLDivElement = document.getElementById('reastaurant_contact_modal') as HTMLDivElement;
        if (div !== null) {
            ReactDOM.render(<RestaurantContactModal
                title={div.dataset.title}
                f_3662={div.dataset.f_3662}
                name={div.dataset.name}
                country={div.dataset.country}
                city={div.dataset.city}
                street={div.dataset.street}
                zip={div.dataset.zip}
                f_984={div.dataset.f_984}
                f_4234={div.dataset.f_4234}
                f_5325={div.dataset.f_5325}
                f_549={div.dataset.f_549}
                contactLName={div.dataset.contactlname}
                contactFName={div.dataset.contactfname}
                contactEmail={div.dataset.contactemail}
                contactPhone={div.dataset.contactphone}
                f_3749={div.dataset.f_3749}
                f_7510={div.dataset.f_7510}
                vat_number={div.dataset.vat_number}
                reg_number={div.dataset.reg_number}
                contactAssignment={div.dataset.contactassignment}
                szolgltatsi_szerzodes={div.dataset.szolgltatsi_szerzodes}
                elkuldes={div.dataset.elkuldes}
                bezaras={div.dataset.bezaras}
            />, div);
        }
    }
}
