import * as React from "react";

// import React = require('react');

interface ITitle{
	field: string
	label: string
}
export interface ITableProps {
	titles:Array<ITitle>
	items:Array<any>
	openEditForm?:Function
	openRemoveConfirm?:Function
}

export interface ITableState {
	filter:string
}

export class Table extends React.Component<ITableProps|any, ITableState|any>{

  	constructor(props:ITableProps){
        super(props);
		this.state = {
			filter: ''
		};
    }

	private filterChangeHandler(evt): void {
		evt.preventDefault();
		this.setState({
			 filter: evt.target.value
		});
	}

	protected filter():Array<any>{
		var ret:Array<any> = [];
        var filter:string = this.state.filter.toString().trim().toLowerCase();
		for (var ikey in this.props.items) {
		    var row = this.props.items[ikey];
			var founded:boolean = false;
			if ( filter === '' ) {
				ret.push(row);
				continue;
			}else{
				for (var key in this.props.titles) {
					var item = this.props.titles[key];
					if ( row[item.field].toLowerCase().indexOf(filter) > -1 ) {
						ret.push(row);
						break;
					}
				}
			}
		}

		return ret;
	}

	private addNewBtnClickHandler(evt): void {
		evt.preventDefault();
		this.props.openEditForm(0);
	}

	private editBtnClickHandler(evt, id:number): void {
		evt.preventDefault();
		this.props.openEditForm(id);
	}
	private removeBtnClickHandler(evt, id:number): void {
		evt.preventDefault();
		this.props.openRemoveConfirm(id);
	}

    render() {
		var items = this.filter();
        return (
            <div>
				<div className="right">
					<button type="button" className="btn mr10" onClick={ e => this.addNewBtnClickHandler(e) }>
						+ Új felvitele
					</button>
					<input type="text" placeholder="Szűrés" className="filter" value={ this.state.filter } onChange={ e => this.filterChangeHandler(e) } />
				</div>
				<table>
					<thead>
						<tr>
							<th>

							</th>
							{ this.props.titles.map((item:ITitle, key) => {
								return <th key={ 't'+key }>
									{ item.label }
								</th>
							}) }
							<th>

							</th>
						</tr>
					</thead>
					<tbody>
						{ items.length === 0? <tr><td className="center" colSpan={ this.props.titles.length + 2 } >
							<strong>
								Nincs találat
							</strong>
						</td></tr>:'' }
						{ items.map((row, rkey) => {
							return <tr key={ rkey }>
								<td>
									<a className="btn small" onClick={ e => this.editBtnClickHandler(e, row.id) }>
										<span className="ion-edit"></span>
									</a>
								</td>
								{ this.props.titles.map((item:ITitle, key) => {
									var temp =  {__html: row[item.field] };
									return <td key={ key } dangerouslySetInnerHTML={temp}>

									</td>
								})}
								<td>
									<a className="btn small" onClick={ e => this.removeBtnClickHandler(e, row.id) }>
										<span className="ion-trash-a"></span>
									</a>
								</td>
							</tr>
						}) }
					</tbody>
					<tfoot>
						<tr>
							<th>

							</th>
							{ this.props.titles.map((item:ITitle, key) => {
								return <th key={ 't'+key }>
									{ item.label }
								</th>
							}) }
							<th>

							</th>
						</tr>
					</tfoot>
				</table>
			</div>
        );
    }
}
