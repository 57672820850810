interface IAjaxData{
	data: any;
	url: string;
	uri_text: string;
}
export default class Ajax{
	constructor(url:string, method:string, data:any, cb:Function, blobMode?:boolean){
		var blob:boolean = false;
		if ( blobMode !== undefined ) {
			blob = blobMode;
		}
		this.send(url, method, data, cb, blob);
	}

	private send(url:string, method:string, data:any, cb:Function, blob:boolean):void{
		var t        = this.createAjaxUrl(url, method, data);
		data         = t.data;
		var url      = t.url;
		var uri_text = t.uri_text;
		var xhr = new XMLHttpRequest();
		xhr.open(method, url);
		xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
		if ( blob === true ) {
			xhr.responseType = 'blob';
		}
		xhr.onload = () =>{
			var response:any = null;
			if (xhr.status === 200) {
				if ( blob === true ) {
					cb(xhr.response);
				}else{
					var bodyFirstChar = xhr.response.substr(0,1);
					if ( /*xhr.response.indexOf('</html>') === -1 ||*/ bodyFirstChar === '{' || bodyFirstChar === '[' ) {
						response = JSON.parse(xhr.response);
					}else{
						response = xhr.response;
					}
					cb(response);
				}
			}else{
				console.error('XHR request faild!', xhr.status);
			}
		};
		xhr.send(uri_text);
	}

	private createAjaxUrl(url:string, method:string, data:any):IAjaxData{
		var uri_text = '';
		for (var key in data) {
			var item = data[key];
			if ( typeof item === 'object' ) {
				for (var ikey in item) {
					var titem = item[ikey];
					//test_index
					if ( typeof titem === 'object' ) {
						for (var tikey in titem) {
							var ttitem = titem[tikey];
                            ttitem                      = encodeURIComponent(ttitem);
                            data[key][ikey][tikey]      = ttitem;
							if (uri_text === '') {
								uri_text += key+'['+ikey+']['+tikey+']=' + ttitem;
							}else{
								uri_text += '&'+key+'['+ikey+']['+tikey+']=' + ttitem;
							}
						}
					}else {
                        titem           = encodeURIComponent(titem);
                        data[key][ikey] = titem;
						if (uri_text === '') {
							uri_text += key+'['+ikey+']=' + titem;
						}else{
							uri_text += '&'+key+'['+ikey+']=' + titem;
						}
					}
				}
			}else {
                item      = encodeURIComponent(item);
                data[key] = encodeURIComponent(item);
				if (uri_text === '') {
					uri_text += key + '=' + item;
				}else{
					uri_text += '&'+key + '=' + item;
				}
			}
		}
		if ( method === 'GET' && uri_text !== '') {
			url += '?'+uri_text;
		}
		return {
			'data' : data,
			'url': url,
			'uri_text':uri_text
		};
	}
}
