import * as React from "react";

interface IModalProps {
    class: string
    title:string
    footer?:string // NOTE: vagy add meg a contentbe...
    closed:Function
}

interface IModalState {
    top:number
    idName:string
}

export default class Modal extends React.Component<IModalProps, IModalState>{

    constructor(props:IModalProps){
        super(props);
        this.state = {
            top: 0,
            idName: 'modal_'+Math.random()
        }
    }
    componentDidMount(){
        console.log('updated');
        this.setPosition();
        document.addEventListener('scroll', (evt) =>{
            evt.preventDefault();            
            if ( document.getElementById(this.state.idName) !== null ) {
                var headerHeight = (document.getElementById(this.state.idName).querySelector('.modal_dialog') as HTMLDivElement ).offsetHeight;
                if ( (window.outerHeight - headerHeight) > ( document.getElementById(this.state.idName).querySelector('.modal_dialog') as HTMLDivElement ).offsetHeight ) {
                    this.setPosition();
                }
            }
        });
    }
    private closeClickHandler(evt): void {
        evt.preventDefault();
        this.props.closed();
    }
    private setPosition():void{
	    this.setState({
             top: window.scrollY
        });

	}

    render() {
        var className = 'ts_modal open ' + this.props.class;
        return (
            <div id={this.state.idName} className={className} style={
                {
                    top: this.state.top + 'px'
                }
            }>
                <div className="modal_dialog">
                    { this.props.title !== ''?<div className="modal_header">
                        { this.props.title }
                        <a className="close_btn" href="#" onClick={ (e) => { this.closeClickHandler(e); } }>
                            <span className="ion-ios-close"></span>
                        </a>
                    </div>:'' }
                    <div className="modal_content">
                        { this.props.children }
                    </div>
                    { this.props.footer !== undefined?<div className="modal_footer">
                        { this.props.footer }
                    </div>:'' }
                </div>
            </div>
        );

    }
}
