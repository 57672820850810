
import * as React from "react";
import * as ReactDOM from "react-dom";
import List from "../avernus/list/source/List";
import Maps, { IMarker } from "../avernus/googlemaps/source/Maps";
import Finder from "./Finder";
import Ajax from "../avernus/ajax/Ajax";

interface IRestaurants extends IMarker{
    city:string
    link:string
    restaurantId:string
}

interface IRestaurantsProps {
    buttonText:string
    finderPlaceHolderText:string
    lang:string
}

interface IRestaurantsState {
    items:Array<IRestaurants>
    titles:Array<any>
    filter:string
    filterOpen:boolean
    message:string
}

export class Restaurants extends React.Component<IRestaurantsProps, IRestaurantsState>{

    private lastFilter:Array<IRestaurants>;

  	constructor(props:IRestaurantsProps){
        super(props);
        this.lastFilter = [];
        this.state = {
            message: 'Betöltés...',
            filter: '',
            filterOpen: false,
            titles: [
                {
                    field: 'title',
                    label: 'title'
                },
                {
                    field: 'address',
                    label: 'address'
                }
            ],
            items: []
        };

        this.init();
    }

    private init():void{
        var url:string='/api';
        if ( this.props.lang === 'EN' ) {
            url = '/api-en';
        }
        if ( this.props.lang === 'DE' ) {
            url = '/api-de';
        }
        new Ajax(url+'/getHomePageList', 'POST', {}, (ret) =>{
            this.setState({
                 items: ret.items,
                 message: 'Nincs találat!'
            });

        });
    }

    private changeFilter(str): void {
        this.setState({
             filter: str
        });
    }

    private changedOpen(): void {
        this.setState({
             filterOpen: !this.state.filterOpen
        });
    }

    protected filter():Array<IRestaurants>{
		var ret:Array<any> = [];
        var filter:string = this.state.filter.toString().trim().toLowerCase();
		for (var ikey in this.state.items) {
		    var row = this.state.items[ikey];
			var founded:boolean = false;
			if ( filter === '' ) {
                ret.push(row);
				continue;
			}else{
				for (var key in this.state.titles) {
					var item = this.state.titles[key];
					if ( row[item.field] !== undefined && row[item.field].toLowerCase().indexOf(filter) > -1 ) {
						ret.push(row);
						break;
					}
				}
			}
		}
		return ret;
	}

    private checkItemsChanged(items:Array<IRestaurants>):boolean{
        if ( this.lastFilter.length !== items.length ) {
            // console.log(items, this.lastFilter);
            this.lastFilter = items;
            return true;
        }
        return false;
    }

    private onlineOrderClickHandler(evt): void {
        evt.preventDefault();
        document.getElementById('test_popup').classList.toggle('show');
    }


    render() {
        var items = this.filter();
        return (
            <div>
                <div className="col2">
                    <div className="box">
                        { items.length === 0?<div className="not_found">
                            { this.state.message }
                        </div>:<div className="list results_div">
                            <List
                                titles = {
                                    this.state.titles
                                }
                                items = {
                                    items
                                }
                                rowTemplate = {
                                    (item) => {
                                        var className = 'row';
                                        if ( item.expired === true ) {
                                            className += ' expired';
                                        }
                                        if ( item.restaurantId == 0 ) {
                                            return <></>
                                        }
                                        return <a href={item.link} target="_blank" className={ className } key={item.lat+'_'+item.lng+'_'+Math.random()}>
                                            <div className="col2">
                                                <div className="box">
                                                    <img src="/img/v2/restaurant.svg" alt=""/>
                                                </div>
                                                <div className="box">
                                                    <span className="title">
                                                        { item.title }
                                                    </span>
                                                    <span className="city">
                                                        { item.address }
                                                    </span>
                                                </div>
                                            </div>
                                        </a>;
                                    }
                                }
                            />
                        </div>
                        }
                    </div>
                    <div className="box">
                        { this.checkItemsChanged(items) === true && items.length > 0?<Maps
                           key="google_map"
                           api="AIzaSyAT-Sa4iX_7_9YD-UgHZ639KB44OhKuLks"
                           mapId="home_map"
                           externalDivMode={true}
                           width="100%"
                           height="315px"
                           mapTypeControl = {false}
                           mapStyle="silver"
                           icons = {[
                               {
                                   name: 'Info',
                                   url: '/img/v2/picker_googlemap.png'
                               },
                               {
                                   name: 'expired',
                                   url: '/img/v2/picker_googlemap_gray.png'
                               }
                           ]}
                           markers = {items}
                           zoom = { 11 }
                           markerClickHandler = { (item) =>{
                               window.location.href = (item as any).link;
                           }}
                       />:<div id="home_map_external_not_this" style={{
                           width: "100%",
                           height: "315px"
                       }}>

                       </div> }
                    </div>
                </div>
                <div className="bottom">
                    <a className="btn order" href="/rendeles">
                        <span className="order_btn_icon"></span> <span className="text">{ this.props.buttonText }</span>
                    </a>
                    <Finder placeholder={ this.props.finderPlaceHolderText } filter={this.state.filter} open={this.state.filterOpen} changed={ (str) => this.changeFilter(str) } changedOpen={ () => this.changedOpen()}  />
                </div>
  			</div>
        );
    }
}
export class InitRestaurants {
    constructor() {
        var div: HTMLDivElement = document.getElementById('restuaurants') as HTMLDivElement;
        if (div !== null) {
            var data:any = div.dataset;
            ReactDOM.render(<Restaurants buttonText={data.button_text} finderPlaceHolderText={data.finder_placeholder_text} lang={data.lang} />, div);
        }
    }
}
